<template>
  <el-row class="row" :gutter="20">
    <el-col :sm="24" :md="12">
      <el-card class="card-left-top" shadow="hover">
        <div style="width: 100%">
          <el-skeleton style="width: 100%" :loading="loadinga" animated>
            <template slot="template">
              <el-skeleton-item variant="h1" style="width: 80px;" />
              <div style="margin-top: 30px;">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 50px;" />
                    <div>
                      <el-skeleton-item variant="test" style="width: 70px;" />
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 50px;" />
                    <div>
                      <el-skeleton-item variant="test" style="width: 70px;" />
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 65px;" />
                    <div>
                      <el-skeleton-item variant="test" style="width: 140px;" />
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top: 25px;">
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 65px;" />
                    <div>
                      <el-skeleton-item variant="test" style="width: 75px;" />
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 65px;" />
                    <div>
                      <el-skeleton-item variant="test" style="width: 80px;" />
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 65px;" />
                    <div>
                      <el-skeleton-item variant="test" style="width: 130px;" />
                    </div>
                  </el-col>
                </el-row>
              </div>
            </template>
            <template>
              <el-collapse>
                <el-descriptions direction="vertical" title="用户信息">
                  <el-descriptions-item label="用户名">
                    {{ name }}
                  </el-descriptions-item>
                  <el-descriptions-item label="隧道数">
                    {{ tunnelstate }} / {{ tunnel }}
                  </el-descriptions-item>
                  <el-descriptions-item label="带宽限制">
                    国内:{{ bandwidth }}m | 国外:{{ bandwidthgw }}m
                  </el-descriptions-item>
                  <el-descriptions-item label="注册邮箱">
                    {{ email }}
                  </el-descriptions-item>
                  <el-descriptions-item label="用户标签">
                    <el-tag :effect="realname === '已实名' ? 'plain' : 'plain'"
                      :type="realname === '已实名' ? 'success' : 'danger'">{{
                        realname }}</el-tag>
                    <el-tag class="tag-spacing" :effect="usergroup === '免费用户' ? 'plain' : 'plain'"
                      :type="usergroup === '免费用户' ? '' : 'warning'">{{ usergroup }}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item v-if="showToken" label="用户密钥">
                    <el-tag @click="copyToken" effect="plain">{{ token }}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item v-else label="用户密钥">
                    <el-tag @click="showToken = true" effect="plain">
                      <i class="el-icon-key"></i>点击查看用户密钥
                    </el-tag>
                  </el-descriptions-item>
                </el-descriptions>
                <el-alert v-if="realname === '未实名'" title="您还没有进行实名认证" type="warning"
                  description="【点击卡片跳转实名】不实名认证则无法使用ChmlFrp提供的映射服务，认证接口对接阿里云二要素实名API。我们允许未成年实名，但请不要使用非本人身份证实名。" show-icon
                  @click.native="redirectToRealname">
                </el-alert>
              </el-collapse>
            </template>
          </el-skeleton>
        </div>
      </el-card>
      <el-card style="margin-top: 16px;">
        <div id="newCharts" shadow="hover">
          <el-skeleton style="width: 100%" :loading="loadingd" animated>
            <template slot="template">
              <el-skeleton :rows="10" />
            </template>
          </el-skeleton>
        </div>
      </el-card>
      <el-card class="card-left-bottom" shadow="hover">
        <div style="width: 100%">
          <el-skeleton style="width: 100%" :loading="loading" animated>
            <template slot="template">
              <div class="jz">
                <el-skeleton-item variant="circle" style="width: 58px; height: 58px; margin-top: 48px;" />
              </div>
              <div class="jz">
                <el-skeleton-item variant="h1" style="width: 200px; margin-top: 25px;" />
              </div>
              <div class="jz">
                <el-skeleton-item variant="span" style="width: 100px; margin-top: 15px;" />
              </div>
              <div style="margin-top: 45px;">
                <el-row :gutter="20" style="margin-top: 25px;">
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 110px;" />
                  </el-col>
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 110px;" />
                  </el-col>
                  <el-col :span="8">
                    <el-skeleton-item variant="p" style="width: 90px;" />
                  </el-col>
                </el-row>
              </div>
              <el-skeleton-item variant="caption" style="margin-top: 20px;" />
              <div style="margin-top: 15px;">
                <el-skeleton-item variant="span" style="width: 80px;" />
              </div>
              <div style="margin-top: 15px;">
                <el-skeleton-item variant="span" />
                <el-skeleton-item variant="span" />
              </div>
            </template>
            <template>
              <el-result icon="success" title="ChmlFrp - Panel v2.2" subTitle="简约 快速 开源"></el-result>
              <el-descriptions>
                <el-descriptions-item label="总隧道数">{{ tunnelCount }}</el-descriptions-item>
                <el-descriptions-item label="总用户数">{{ userCount }}</el-descriptions-item>
                <el-descriptions-item label="总节点数">{{ nodeCount }}</el-descriptions-item>
              </el-descriptions>
              <el-divider></el-divider>
              <span>友情链接</span>
              <div style="margin-top: 15px;">
                <el-link type="info" href="https://www.ixmu.net/" class="ky">黑软小栈</el-link>
                <el-link type="info" href="https://www.fengidc.cn/" class="ky">FENGIDC</el-link>
                <el-link type="info" href="https://www.locyanfrp.cn/" class="ky">LoCyanFrp</el-link>
                <el-link type="info" href="https://www.cnsvs.com.cn/" class="ky">CN.SVS</el-link>
                <el-link type="info" href="https://dom.cloudery.cn/" class="ky">云竹域名</el-link>
                <el-link type="info" href="https://xhfrp.xhuzim.top/" class="ky">xinghefrp</el-link>
              </div>
            </template>
          </el-skeleton>
        </div>
      </el-card>
    </el-col>
    <el-col :sm="24" :md="12">
      <el-card>
        <el-skeleton :loading="loadingb" animated>
          <template slot="template">
            <el-skeleton-item variant="h1" style="width: 80px;" />
            <div>
              <el-skeleton-item variant="text" style="width: 50%; margin-top: 16px;" />
              <div style="margin-top: 16px;">
                <el-skeleton-item variant="button" style="height: 35px; width: 120px;" />
                <el-skeleton-item variant="button" style="margin-left: 10px; height: 35px; width: 80px;" />

              </div>
            </div>
          </template>
          <template>
            <div v-if="is_signed_in_today === false">
              <div>
                <span style="font-size: 18px;">每日签到</span>
              </div>
              <div style="margin-top: 20px;">
                <span style="font-size: 14px;">每日签到即可获取1~300点积分，积分可用于购买增值服务</span>
                <div style="margin-top: 16px;">
                  <el-popover trigger="click">
                    如果此框为空白，请刷新网页或稍等一小会
                    <div id="grecaptcha"></div>
                    <el-button type="primary" size="medium" plain slot="reference" v-loading="loadingc">
                      点击这里签到
                    </el-button>
                  </el-popover>
                  <el-popover style="margin-left: 10px;" placement="top-start" title="统计信息" width="200" trigger="hover">
                    <div>
                      总共签到：{{ totalSignIns }} 次<br />总签到积分：{{ totalPoints }} 分<br />上次签到时间：{{ lastSignInTime }}
                    </div>
                    <el-button size="medium" slot="reference" plain>统计信息</el-button>
                  </el-popover>
                </div>
              </div>
            </div>
            <div v-else>
              <div>
                <span style="font-size: 18px;">每日签到</span>
              </div>
              <div style="margin-top: 20px;">
                <span style="font-size: 14px;">您今天已经签到过了噢</span>
                <div style="margin-top: 16px;">
                  <el-popover placement="top-start" title="统计信息" width="200" trigger="hover">
                    <div>
                      总共签到：{{ totalSignIns }} 次<br />总签到积分：{{ totalPoints }} 分<br />上次签到时间：{{ lastSignInTime }}
                    </div>
                    <el-button size="medium" slot="reference" plain>统计信息</el-button>
                  </el-popover>
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </el-card>
      <el-card class="card-right">
        <h1>面板公告及常见问题</h1>
        <span>如果有其他问题可前往docs.chcat.cn</span>
        <div>QQ一群：<el-link type="primary" :underline="false"
            href="https://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=Pz3PtGpjsPo8rQQ6cqPCjlVaz-u6y0EM&authKey=jSwtgPD0mc9berZBSnfyw8eJoaGFXflpEWSj%2FlqPNpq%2BzfxGVfK7kAARfANsJYWo&noverify=0&group_code=992067118">992067118</el-link>
        </div>
        <el-collapse>
          <el-collapse-item title="注意事项" name="0">
            <div>使用chmlfrp映射远程桌面、数据库等，映射后会暴露在公网环境。您应该注意您的数据安全，设置安全的密码。因此类问题造成的锁机·重要文件被盗，我们概不负责。</div>
          </el-collapse-item>
          <el-collapse-item title="关于实名" name="1">
            <div>实名认证接口对接阿里云二要素接口。允许未成年认证，但是请不要使用非本人身份证。</div>
            <div>如果实名认证接口出现实名认证失败等问题请加入交流群联系群主。或发送邮件至chaoji@chcat.cn</div>
          </el-collapse-item>
          <el-collapse-item title="延迟问题" name="2">
            <div>节点请选择离你(开服者)最近的节点。您可以根据节点状态页中的节点负载选择负载较低的节点，这能够优化您的体验。</div>
            <div>服务器卡顿不一定为映射的原因。这里使用mc服务器举例，您可以通过查看服务器TPS或MSPT查看是否为服务器硬件负载过高。若MSPT或TPS在正常范围内，但服务器延迟依旧很高，您可以考虑购买会员获得更高的带宽。
            </div>
          </el-collapse-item>
          <el-collapse-item title="信息安全" name="3">
            <div>我们会保存用户的实名信息及用户数据，但是留存的用户数据全部采用业内标准的加密格式</div>
            <div>实名信息采用AES 256 CBC加密。密码信息通过MD5加密</div>
            <div>我们承诺不会泄露用户的任何信息，也不会拿用户的信息开玩笑。API数据传递通过SSL加密，API也经过了一层混淆加密。</div>
          </el-collapse-item>
          <el-collapse-item title="关于token" name="4">
            <div>用户token是除了账户密码外最重要的东西。有了token就相当于有了你账户的账户密码。请妥善保管token</div>
            <div>TOKEN不要外传！！如果不经意见泄露token，请前往个人中心页面点击重置token</div>
          </el-collapse-item>
          <el-collapse-item title="禁止内容" name="5">
            <div>不能使用映射搭建关于赌博, 黄色, 血腥, 暴力, 爆破, 发包, 代刷网, 发卡网等违反中国法律的站点或服务</div>
            <div>一旦发现，我们有权强行停止您的映射服务。并且上报给公安机关。</div>
          </el-collapse-item>
          <el-collapse-item title="关于带宽" name="6">
            <div>为了保证速率及您的体验，我们会按照节点分开计算带宽(假如您为免费用户且创建了两个隧道，一个为北京，一个为成都。则北京和成都都能达到满速的5m，也就是总带宽10m)。</div>
            <div>我们允许用户搭建网盘，图床等高带宽占用服务。但推荐使用国外大带宽服务器搭建(国外服务器带宽速度x4，假如你为免费用户，则限速会提升至20m，且和其他节点分开计算带宽)。</div>
          </el-collapse-item>
          <el-collapse-item title="警告次数" name="7">
            <div>警告次数是为了维护映射良好使用环境及社区。轻度违规一次基于一次警告，警告次数+1。一旦警告次数满3次，将直接封禁账户</div>
            <div>警告次数每隔一个月将会归零，所以被警告一两次不必太过于担心。</div>
          </el-collapse-item>
          <el-collapse-item title="关于节点" name="8">
            <div>我们不能保证所有的节点都稳定可用。使用人数越多，带宽占用越大的节点越容易出现卡顿情况。</div>
            <div>如果想要稳定且高速的节点，可以购买vip使用vip专属节点。</div>
          </el-collapse-item>
          <el-collapse-item title="端口问题" name="9">
            <div>内网端口选择你要映射的端口，外网端口推荐随机。</div>
            <div>内网端口不受限制，外网端口不能与其他外网端口重复(会自动检测)。同时不能使用80/443这类特殊端口。</div>
          </el-collapse-item>
          <el-collapse-item title="贡献节点" name="10">
            <div>如果您有多余的公网服务器想赞助给我们，可联系QQ：242247494。</div>
            <div>赞助节点的用户可免费获得SVIP，期限直至您提供的节点无法使用。</div>
          </el-collapse-item>
          <el-collapse-item title="友链申请" name="11">
            <div>我们目前允许添加友链，不过友情链接需要是不违反国家法律的网站。非盈利性网站可直接申请添加。盈利性网站需要附带网站备案号以及营业执照副本图片。</div>
            <div>友链的期限不固定，如发现违规，我们有权直接删除友链。并且友链的期限不是永久，我们有权不通知你直接删除。</div>
            <div>友链申请详情规则请前往：https://docs.chcat.cn/docs/chmlfrp/yl</div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { getToken, setToken, removeToken } from '../utils/setToken';
import axios from 'axios';
export default {
  data() {
    return {
      showToken: false,
      token: '',
      tunnelstate: 0,
      tunnelCount: 0,
      userCount: 0,
      nodeCount: 0,
      loading: false,
      loadinga: false,
      loadingb: false,
      loadingc: false,
      loadingd: false,
      sitekey: "6LetQ4YoAAAAANuUUzVy-ID4rQ8YhYmotBFUhNIj",
      is_signed_in_today: false,
      todaySignInCount: '',
      totalSignIns: '',
      totalPoints: '',
      lastSignInTime: '',
    }
  },
  created() {
    const usertoken = getToken('token');
    this.token = getToken("token")
    this.name = getToken("username")
    this.email = getToken("email")
    this.usergroup = getToken("usergroup")
    this.realname = getToken("realname")
    this.bandwidth = getToken("bandwidth")
    this.tunnel = getToken("tunnel")
    this.tunnelstate = getToken("tunnelstate")
    this.bandwidthgw = getToken("bandwidth") * 4
    this.showCharts();

    if (usertoken) {
      this.setData();
    } else {
      removeToken('token');
      this.$router.push('/login');
      this.$message({
        message: "无效的token，请重新登录",
        type: "error",
      });
    }
  },
  mounted(params) {
    this.loaded();
    this.tunnelstate = getToken("tunnelstate")
    this.showCharts(); // 折线图
    this.loading = true,
      // 在组件挂载后，使用axios发送GET请求到PHP API获取数据
      axios.get('https://cf-v1.uapis.cn/api/sinfo.php')
        .then((response) => {
          // 将获取到的数据填充到组件数据中
          this.tunnelCount = response.data.tunnel_count;
          this.userCount = response.data.user_count;
          this.nodeCount = response.data.node_count;
        })
        .catch((error) => {
          console.error('获取数据失败:', error);
        }).finally(() => {
          this.loading = false; //结束确认按钮加载状态
        });
    const userid = getToken('userid');
    this.loadingb = true,
      axios
        .get('https://cf-v1.uapis.cn/api/qdxx.php', {
          params: { userid, ...params },
        })
        .then(response => {
          this.lastSignInTime = response.data.last_sign_in_time;
          this.totalPoints = response.data.total_points;
          this.totalSignIns = response.data.total_sign_ins;
          this.todaySignInCount = response.data.count_of_matching_records;
          this.is_signed_in_today = response.data.is_signed_in_today;
        })
        .finally(() => {
          this.loadingb = false; //结束确认按钮加载状态
        });
  },
  methods: {
    copyToken() {
      const textArea = document.createElement('textarea');
      textArea.value = this.token;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.showToken = false;
      this.$message.success('Token 已复制');
    },

    redirectToRealname() {
      this.$router.push('/user/Realname');
    },

    setData(params) {
      this.loadinga = true;
      const usertoken = getToken('token');
      axios
        .get('https://cf-v1.uapis.cn/api/userinfo.php', {
          params: { usertoken, ...params },
        })
        .then(response => {
          if (response.data.error) {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
              message: "无效的token，请重新登录",
              type: "error",
            });
          } else {
            const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

            setToken('userid', userid);
            setToken('username', username);
            setToken('email', email);
            setToken('qq', qq);
            setToken('usergroup', usergroup);
            setToken('bandwidth', bandwidth);
            setToken('tunnel', tunnel);
            setToken('realname', realname);
            setToken('integral', integral);
            setToken('userimg', userimg);
            setToken('term', term);
            setToken('tunnelstate', tunnelstate)
          }
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
        })
        .finally(() => {
          this.loadinga = false;
        })
    },

    updateChartWithData(apiData) {
      const xAxisData = apiData.data.map(entry => entry.time);
      const uploadData = apiData.data.map(entry => this.bytesToMiB(entry.traffic_in));
      const downloadData = apiData.data.map(entry => this.bytesToMiB(entry.traffic_out));

      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('newCharts'));
      // 绘制图表
      myChart.setOption({
        title: {
          text: '流量消耗'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {},
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} MiB'
          }
        },
        series: [
          {
            name: '上传流量',
            type: 'line',
            data: uploadData,
            markPoint: {
              // data: [
              //   { type: 'max', name: 'Max' },
              //   { type: 'min', name: 'Min' }
              // ]
            },
            markLine: {
              data: [
                { type: 'average', name: 'Avg' },
                [
                  {
                    symbol: 'none',
                    x: '90%',
                    yAxis: 'max'
                  },
                  {
                    symbol: 'circle',
                    label: {
                      position: 'start',
                      formatter: 'Max'
                    },
                    type: 'max',
                    name: '最高点'
                  }
                ]
              ]
            }
          },
          {
            name: '下载流量',
            type: 'line',
            data: downloadData,
            markPoint: {
              // data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
            },
            markLine: {
              data: [
                { type: 'average', name: 'Avg' },
                [
                  {
                    symbol: 'none',
                    x: '90%',
                    yAxis: 'max'
                  },
                  {
                    symbol: 'circle',
                    label: {
                      position: 'start',
                      formatter: 'Max'
                    },
                    type: 'max',
                    name: '最高点'
                  }
                ]
              ]
            }
          }
        ]
      })
    },
    bytesToMiB(bytes) {
      return (bytes / (1024 * 1024)).toFixed(2);
    },
    // 从 API 获取数据并更新图表
    async showCharts() {
      try {
        // 定义 GET 参数
        this.loadingd = true;
        const usertoken = getToken("token")

        // 使用模板字符串构建 API 请求 URL，包含 GET 参数
        const apiUrl = `https://cf-v1.uapis.cn/api/flow_zong.php?usertoken=${usertoken}`;

        // 发起 API 请求
        const response = await axios.get(apiUrl);

        // 处理 API 响应
        if (response.data.status === 'success') {
          // 处理数据并更新图表
          this.loadingd = false;
          this.updateChartWithData(response.data);
        } else {
          console.error('API 请求失败');
        }
      } catch (error) {
        console.error('API 请求错误', error);
      }
    },
    qiandao: function (g_recaptcha_response) {
      this.loadingc = true;
      const usertoken = getToken('token');

      const formData = new FormData();
      formData.append('usertoken', usertoken);
      formData.append('g_recaptcha_response', g_recaptcha_response);

      axios
        .post('https://cf-v1.uapis.cn/api/qiandao.php', formData)
        .then(response => {
          if (response.data.status === 'fail') {
            this.$message({
              message: response.data.message,
              type: "error",
            });
          } else {
            this.$alert('您本次签到获得积分：' + response.data.points, '签到成功', {
              confirmButtonText: '确定',
              type: 'success',
            }).then(() => {
              this.$message({
                type: 'success',
                message: '签到成功'
              });
              location.reload();
            });
          }
        }).finally(() => {
          this.loadingc = false;
        });
    },
    loaded() {
      setTimeout(() => {
        window.grecaptcha.render("grecaptcha", {
          sitekey: this.sitekey,
          callback: this.qiandao
        });
      }, 200);
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  min-height: 400px;

  .card-left-top {
    min-height: 200px;
  }

  .card-left-bottom {
    min-height: 200px;
    margin-top: 16px;
  }

  .card-right {
    min-height: 416px;
    margin-top: 16px;
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.fa {
  font-size: 25px;
}

.ky {
  width: 70px;
  line-height: 30px;
}

.tag-spacing {
  margin-left: 2px;
}

.jz {
  display: flex;
  justify-content: center;
}

#newCharts {
  margin: 0 auto;
  height: 400px;
}
</style>
